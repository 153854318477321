<template>
    <div class="administrationcoupons-container">
        <slideout-panel></slideout-panel>
    
        <vs-row justify="flex-end" style="width:100%;">
            <vs-button size="large" @click="createNew">Crea Nuovo</vs-button>
        </vs-row>
    
        <vs-table v-model="selected" style="height: 80vh; width: 100%; margin-top: 20px;">
            <template #thead>
                <vs-tr>
                    <vs-th>
                        Nome
                    </vs-th>
                    <vs-th>
                        Utilizzi massimi
                    </vs-th>
                    <vs-th>
                        Sconto (%)
                    </vs-th>
                </vs-tr>
            </template>
            <template #tbody>
                <vs-tr :key="i" v-for="(tr, i) in coupons" :data="tr" :is-selected="selected == tr">
                    <vs-td>
                        {{ tr.name }}
                    </vs-td>
                    <vs-td>
                        {{ tr.maximum_uses }}
                    </vs-td>
                    <vs-td>
                        {{ tr.discount_percentage }}
                    </vs-td>
                </vs-tr>
            </template>
            <template #footer>
                <vs-row justify="center" align="center">
                    <vs-button :disabled="result == null || result.before == null" @click="previousPage">
                        <img src="../../assets/icons/previous_page.png" />
                    </vs-button>
                    <vs-button :disabled="result == null || result.after == null" @click="nextPage">
                        <img src="../../assets/icons/next_page.png" />
                    </vs-button>
                </vs-row>
    
            </template>
        </vs-table>
    </div>
    </template>
    
        
    <script>
    import {
        apiCall
    } from '../../utils/ApiMiddleware';
    import EditCoupon from '../../components/elements/EditCoupon';
    export default ({
        data() {
            return {
                coupons: [],
                selected: null,
                filters: {},
                result: null,
                panel: null
            }
        },
        props: {
            user: {
                type: Object,
                required: false
            }
        },
    
        mounted() {
            this.getcoupons(this.filters)
        },
    
        methods: {
            createNew() {
                this.selected = {
                    id: null,
                    name: '',
                    discount_percentage: '',
                    maximum_uses: '',
                    plan_ids: [],
                }
            },
            previousPage() {
                this.filters.before = this.result.before;
                this.filters.after = null;
                this.filters.__ob__.dep.notify();
            },
    
            nextPage() {
                this.filters.after = this.result.after;
                this.filters.before = null;
                this.filters.__ob__.dep.notify();
            },
    
            async getcoupons(p) {
                this.selected = null;
                const loading = this.$vs.loading();
                const response = await apiCall('GET', '/backoffice/coupons', p);
                loading.close();
                // if status code is 200, show a success message 
                if (response.status == 200) {
                    console.log(response.data.data)
                    this.coupons = response.data.data.map(e => ({
                        ...e,
                        plan_ids: e.plans ? e.plans.map(e => e.id) : []
                    }));
                    this.result = response.data;
                } else if (response.status != 0) {
                    this.$vs.notification({
                        title: this.$t('common.messages.somethingWentWrong'),
                        text: 'Impossibile caricare i coupon',
                        color: 'danger',
                        position: 'top-right'
                    });
                }
    
            },
    
            async editCoupon(notification) {

                // convert the string to a number
                notification.maximum_uses = parseInt(notification.maximum_uses);
                notification.discount_percentage = parseInt(notification.discount_percentage);

                const response = await apiCall('POST', '/backoffice/coupons', notification);
                // if status code is 200, show a success message 
                if (response.status == 200) {
                    
                    this.$vs.notification({
                        title: this.$t('common.messages.success'),
                        text: 'Coupon creato con successo',
                        color: 'success',
                        position: 'top-right'
                    });
                    await this.getcoupons();
                    this.selected = this.coupons.find(e => e.id == response.data.id);
                } else if (response.status != 0) {
                    this.$vs.notification({
                        title: this.$t('common.messages.somethingWentWrong'),
                        text: 'Impossibile creare il coupon',
                        color: 'danger',
                        position: 'top-right'
                    });
                }
            }
        },
    
        watch: {
            filters(n) {
                this.getcoupons(n);
            },
            async selected(n) {
                
                console.log(n)
                var vm = this;
    
                if (this.panel != null) {
                    await this.panel.hide();
                    this.panel = null;
                }

                if (n == null) {
                    return;
                }
                this.panel = await this.$showPanel({
                    component: EditCoupon,
                    openOn: 'right',
                    props: {
                        coupon: n,
                        editCoupon: this.editCoupon,
                        deleted: () => {
                            this.getcoupons()
                        }
                    }
                });
                console.log(this.panel)
    
                this.panel.promise
                    .then(() => {
                        vm.selected = null;
                    });
    
            }
        },
    })
    </script>
    
        
    <style scoped>
    .administrationcoupons-container {
        padding-left: 70px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
    }
    </style>
    